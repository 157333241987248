import React from 'react';
import Router, { withRouter } from 'next/router';
import Head from 'next/head';
import NProgress from 'nprogress';

import 'moment/locale/pt-br';

import '../styles/index.scss';

NProgress.configure({ showSpinner: false });

// @ts-ignore
Router.onRouteChangeStart = (url) => {
  console.info(`Loading: ${url}`);
  NProgress.start();
};
// @ts-ignore
Router.onRouteChangeComplete = () => NProgress.done();
// @ts-ignore
Router.onRouteChangeError = () => NProgress.done();

/**
 * @param {{
 *  Component: typeof import('react').Component,
 *  pageProps: {
 *   initialState: any,
 *  },
 *  router: import('next/router').Router,
 * }} props
 */
const App = ({
  Component, pageProps, router
}) => (
  <div>
    <Head>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
    </Head>

    <Component {...pageProps} router={router} url={router} />
  </div>
);

export default withRouter(App);
